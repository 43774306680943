import React from 'react';
import bgImage from './bpeople.png';

const App = () => {
  return (
    <div className="flex flex-col min-h-screen text-white font-spectral">
      <style jsx>{`
        .custom-bullet {
          list-style: none;
        }
        .custom-bullet li {
          position: relative;
          padding-left: 1.5em;
        }
        .custom-bullet li::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0.6em;
          width: 0.6em;
          height: 0.6em;
          border: 1px solid white;
          border-radius: 50%;
        }
      `}</style>

      {/* Navbar */}
      <header className="bg-black p-4">
        <div className="container mx-auto">
          <h1 className="text-3xl font-bold">Velocity Consulting</h1>
        </div>
      </header>

      {/* Main content */}
      <main 
        className="flex-grow bg-cover bg-center flex items-start pt-12" 
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="container mx-auto h-full py-8">
          <div className="w-full lg:w-3/4 h-full flex flex-col">
            {/* Introduction */}
            <div className="mb-12">
              <h2 className="text-5xl leading-tight mb-8 italic font-bold">
                A global independent investment consulting boutique providing strategic advice for M&A deals and corporate finance.
              </h2>
            </div>

            {/* Bullet points */}
            <div className="mb-auto">
              <h3 className="text-4xl font-semibold mb-6">
                We assist with:
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 pb-5">
                <ul className="custom-bullet space-y-4">
                  <li className="text-2xl font-semibold">Finding strategic/financial buyers for your company</li>
                  <li className="text-2xl font-semibold">Identifying acquisition targets</li>
                  <li className="text-2xl font-semibold">Structuring financing for your business</li>
                </ul>
                <ul className="custom-bullet space-y-4">
                  <li className="text-2xl font-semibold">Supporting due diligence</li>
                  <li className="text-2xl font-semibold">Developing transaction structures</li>
                  <li className="text-2xl font-semibold">Negotiating SPAs and other legal documents</li>
                </ul>
              </div>
            </div>

            {/* Clients */}
            <div className="mt-12">
              <h3 className="text-4xl font-semibold mb-4">
                Our clients include:
              </h3>
              <p className="text-2xl font-semibold">
                XTX Markets, ETC Group (acquired by Bitwise in August 2024), and Freedom Finance Europe.
              </p>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-black p-4">
        <div className="container mx-auto">
          <p className="text-base">&copy; 2024 Velocity Consulting. All rights reserved. | Please contact us at info@VelocityC.com</p>
        </div>
      </footer>
    </div>
  );
};

export default App;